<template>
  <div id="app">
    <Nav :pt="isIndex" />
    <div class="pageMain">
      <div>
        <video
          class="video"
          controls="controls"
          poster="@/assets/p12.png"
          style="width:100%"
          src="https://suco.oss-cn-hangzhou.aliyuncs.com/5cdbe989-1775ccf1852-0006-d53d-abc-07b48.mp4"
        >
          your browser does not support the video tag
        </video>
      </div>
      <div class="p30">
        <div class="boxText">
          {
          苏州民族管弦乐团利用数字技术为代表的新媒体，打破了传统媒介之间的壁垒，使传统民族交响乐的表现形式更加丰富生动。将文字、音频、画面融为一体，借助新媒体广受年轻受众群体喜爱，交互性极强的特点，做到即时地、无限地扩展内容，使得传统民族乐传播更加迅速和生动，更加容易被目标大众所接受
          」
        </div>
      </div>

      <div class="textTitle4"><span>新媒体平台运营</span></div>

      <div class="newsPic mt20">
        <div class="newsPicPad">
          <swiper class="newsOptions" :options="newsOptions">
            <swiper-slide>
              <div><img src="@/assets/media/douyin.jpg" /></div>
              <div class="p10 tc">抖音</div>
            </swiper-slide>
            <swiper-slide>
              <div>
                <a href="https://space.bilibili.com/495105484" target="_blank">
                  <img src="@/assets/media/bzhan.jpg" />
                </a>
              </div>
              <div class="p10 tc">B站</div>
            </swiper-slide>
            <swiper-slide>
              <div>
                <a
                  href="https://www.youtube.com/channel/UCD-grReY4V32QAQe3Z8dWjw"
                  target="_blank"
                  ><img src="@/assets/media/ytb.jpg" />
                </a>
              </div>
              <div class="p10 tc">YOUTUBE</div>
            </swiper-slide>
          </swiper>
        </div>
        <div class="newsPicNext"></div>
        <div class="newsPicPrev"></div>
      </div>

      <div class="musicBox mt20">
        <div class="musicCover">
          <div class="musicCoverCd">
            <span>项目名称项目名称项目名称项目名称</span>
          </div>
        </div>

        <h3 class="musicTitle">项目名称</h3>
        <div class="musicAuthor">作曲家 演奏者</div>
        <div class="musicProgress">
          <span class="musicProgressLeft">00:23</span>
          <span class="musicProgressRight">04:21</span>
          <div class="musicProgressBar">
            <span></span>
            <b></b>
          </div>
        </div>

        <div class="musicTool">
          <span class="fastBack"
            ><img src="@/assets/fastBack.png" width="60"
          /></span>
          <span class="musicPlay"
            ><img src="@/assets/start.png" width="80"
          /></span>
          <span class="forward"
            ><img src="@/assets/forward.png" width="60"
          /></span>
          <span class="musicVoice"
            ><img src="@/assets/voice.png" width="25"
          /></span>
        </div>
      </div>

      <Footer />
    </div>
  </div>
</template>

<style scoped></style>
<script>
import Nav from "@/components/nav.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "App",
  components: {
    Nav,
    Footer,
  },
  data() {
    return {
      isIndex: false,
      newsPic: [
        {
          title: "抖音",
          img: require("@/assets/media/douyin.jpg"),
        },
        {
          title: "b站",
          img: require("@/assets/media/bzhan.jpg"),
        },
        {
          title: "YOUTUBE",
          img: require("@/assets/media/ytb.jpg"),
        },
      ],
      newsOptions: {
        slidesPerView: 3,
        spaceBetween: 30,
        centeredSlides: true,
        loop: true,
        navigation: {
          nextEl: ".newsPicNext",
          prevEl: ".newsPicPrev",
        },
        // Some Swiper option/callback...
      },
    };
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>
